import { NextPage } from 'next'
import Page from '@components/page'
import Container from '@components/core/container'
import AuthForm, { AuthFormType } from '@components/auth/form'
import { FlexAlign, FlexJustify } from '@lib/theme'
import Block from '@components/core/block'
import Head from 'next/head'
import Logo from '@components/core/logo'
import Link from 'next/link'
import { P } from '@components/core/paragraph'

const LoginPage: NextPage = () => {
  return (
    <Page noAuthCheck noSidebar>
      <Head>
        <title>Ancho - Login</title>
      </Head>
      <Container
        desktopWrap
        flexAlign={FlexAlign.Center}
        flexJustify={FlexJustify.Center}
        fullHeight
        largeDesktopWrap
      >
        <Block style={{ marginBottom: '30px', textAlign: 'center' }}>
          <Logo height={45} />
        </Block>
        <AuthForm type={AuthFormType.Login} />

        <Block style={{ marginBottom: '30px', textAlign: 'center' }}>
          Don't have an account? <Link href="/signup">Sign up</Link>
        </Block>
      </Container>
    </Page>
  )
}

export default LoginPage
